/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import * as api from '../api/api';
import {createToast, STATUS_TYPE} from '../ux/ux';
import * as types from './action-types';
import {API_STATUS} from '../api/json-fetch';
import {clearStorageItem, loadStorageItem, saveStorageItem, storageItems} from '../utils/local-storage';
import {catchLostServerConnection, createToastFromJsonErrors} from './error-handlers.actions';
import history from '../history';
import {fetchPolicies} from './methods-display.actions';
import {OSP_LOGON_URL, SSO_DEFAULT_LOGOUT_REDIRECT} from './navigation.actions';
import {methodIds} from '../data/MethodData';
import t from '../i18n/locale-keys';

const CHECK_LOGIN_SESSION_INTERVAL = 60 * 1000;
const IMPLEMENTED_AUTH_METHODS = [methodIds.PASSWORD, methodIds.LDAP_PASSWORD];   // OSP will replace
let LOGIN_SESSION_TIMEOUT_MS;
if (process.env.NODE_ENV === 'development') {
    LOGIN_SESSION_TIMEOUT_MS = 4 * 60 * 60 * 1000;  // 4 hours in development
} else {
    LOGIN_SESSION_TIMEOUT_MS = 5 * 60 * 1000;  // 5 min in production
}

let checkLoginSessionIntervalID = null;
let lastActivityTime = new Date();      // Keep track of last activity time so we can logout user if idle too long

export function authenticateUser(username, methodId, formData) {
    return (dispatch, getStore) => {
        dispatch({type: types.AUTHENTICATE_USER_REQUEST});

        return api.beginLogonProcess(username, methodId, false)
            .then(({logonProcessId}) => {
                return api.doLogon(logonProcessId, formData, false);
            })
            .then(result => {
                if (result.status === 'OK') {
                    setCheckLoginSessionInterval(dispatch, getStore);
                    saveStorageItem(storageItems.LOGIN_SESSION_ID, result.loginSessionId);
                    dispatch({type: types.AUTHENTICATE_USER_SUCCESS, result});
                    fetchPolicies()(dispatch, getStore);      // Load policies so deleteme button shows up as needed
                } else {
                    createToast({type: STATUS_TYPE.ERROR, description: result.msg});
                }
            })
            .catch(catchLostServerConnection)
            .catch(createToastFromJsonErrors)
            .catch((failedResponseData) => {
                dispatch({type: types.AUTHENTICATE_USER_FAILURE, failedResponseData});
            });
    };
}

export const deleteLogonProcess = (logonProcessId) => () => {
    return api.deleteLogonProcess(logonProcessId)
        .catch(catchLostServerConnection)
        .catch(createToastFromJsonErrors);
};

export const deleteUser = () => (dispatch, getStore) => {
    const {userId, loginSessionId, tenantName} = getStore().authentication;

    return api.deleteUser(userId, loginSessionId)
        .then(() => {
            clearCheckLoginSessionInterval();
            clearStorageItem(storageItems.LOGIN_SESSION_ID);
            clearStorageItem(storageItems.TENANT_NAME);
            clearStorageItem(storageItems.IS_HOSTED_SAAS);
            dispatch({type: types.BEGIN_SIGN_OUT});
            dispatch({type: types.CLEAR_USER_INFO});

            return api.requestOSPLogout(tenantName);
        })
        .catch(catchLostServerConnection)
        .catch(createToastFromJsonErrors);
};

export const doTestLogon = (templateId, logonProcessId, formData, keepCamelCase) => (dispatch, getStore) => {
    const {userId, loginSessionId} = getStore().authentication;

    return api.doTestLogon(userId, loginSessionId, templateId, logonProcessId, formData, keepCamelCase)
        .catch(catchLostServerConnection)
        .catch(createToastFromJsonErrors);
};

export function handleExpiredLoginSession(dispatch, tenantName) {
    clearCheckLoginSessionInterval();
    clearStorageItem(storageItems.LOGIN_SESSION_ID);    // Clear expired session from storage
    clearStorageItem(storageItems.TENANT_NAME);
    clearStorageItem(storageItems.IS_HOSTED_SAAS);
    dispatch({type: types.CLEAR_USER_INFO});
    createToast({
        type: STATUS_TYPE.ERROR,
        description: t.loginSessionExpired()
    });
    api.requestOSPLogout(tenantName);
}

const checkLoginSession = (dispatch, getStore) => {
    const {loginSessionId, tenantName} = getStore().authentication;

// Expire login session if it has been too long since time of last activity.
    const currentTime = new Date();
    if (currentTime - lastActivityTime > LOGIN_SESSION_TIMEOUT_MS) {
        handleExpiredLoginSession(dispatch, tenantName);
        return;
    }

    api.readLoginSessionInfo(loginSessionId, tenantName)
        .catch((failedResponseData) => {
            if (failedResponseData.status === API_STATUS.INVALID_LOGIN_SESSION) {
                handleExpiredLoginSession(dispatch, tenantName);
            } else {
                console.log('Unable to communicate with server to check login session');
            }
        });
};

export const checkMultitenancyStatus = () => (dispatch) => {
    api.getStatus()
        .then(response => {
            const tenancyMode = response.multitenancyMode;
            const hostedSaasMode = response.hostedSaasMode;
            saveStorageItem(storageItems.IS_HOSTED_SAAS, hostedSaasMode);
            // If multitenancy is enabled then fetch the effective policies for login page
            if (tenancyMode) {
                api.fetchEffectivePolicies()
                    .then(response => {
                        const effectivePolicy = response.policy;
                        dispatch({type: types.SET_EFFECTIVE_POLICY, effectivePolicy});
                        dispatch({type: types.SET_MULTI_TENANCY_STATUS, tenancyMode});
                    });
            } else {
                dispatch({type: types.SET_MULTI_TENANCY_STATUS, tenancyMode});
            }
        });
};

function clearCheckLoginSessionInterval() {
    if (checkLoginSessionIntervalID) {
        clearInterval(checkLoginSessionIntervalID);
        checkLoginSessionIntervalID = null;
    }
}

export function clearUser() {
    return (dispatch) => dispatch({type: types.CLEAR_USER_INFO});
}

export const logoutUser = () => (dispatch, getStore) => {
    const {loginSessionId, tenantName} = getStore().authentication;
    let webauthLogout = false;

    return api.deleteLoginSession(loginSessionId)
        .catch(reason => {
            if (reason.status === 405) {
                console.log('Webauth was used to login');
                webauthLogout = true;
                // HACK User used Webauth to login, we need to do the real logout.  See `WebAuthLogoutHandler`
                const webauthUrl = `/webauth/logout?login_session_id=${loginSessionId}`;
                window.open(webauthUrl, '_self');
            }
        })
        .finally(() => {
            if (webauthLogout) {
                console.log('Skipping OSP logout because Webauth was used');
                return;
            }
            console.debug('Calling OSP logout');
            clearCheckLoginSessionInterval();
            clearStorageItem(storageItems.LOGIN_SESSION_ID);
            clearStorageItem(storageItems.TENANT_NAME);
            clearStorageItem(storageItems.IS_HOSTED_SAAS);
            dispatch({type: types.CLEAR_USER_INFO});
            createToast({type: STATUS_TYPE.OK, description: t.userSignOutSuccessful()});
            return api.requestOSPLogout(tenantName);
        });
};

export function loadLoginChains(username) {
    return (dispatch) => {
        dispatch({type: types.LOGIN_CHAINS_REQUEST});

        return api.getLoginChains(username)
            .then((data) => {
                if (data.userIsLocked) {
                    createToast({
                        type: STATUS_TYPE.ERROR,
                        description: t.userLockedOut()
                    });
                } else {
                    const chains = data.chains.filter((chain) => {
                        for (let methodIndex = 0; methodIndex < chain.methods.length; methodIndex++) {
                            if (IMPLEMENTED_AUTH_METHODS.indexOf(chain.methods[methodIndex]) === -1) {
                                return false;
                            }
                        }
                        return true;
                    });
                    dispatch({type: types.LOGIN_CHAINS_SUCCESS, chains});
                }
            })
            .catch(catchLostServerConnection)
            .catch(createToastFromJsonErrors)
            .catch((failedResponseData) => {
                dispatch({type: types.LOGIN_CHAINS_FAILURE, failedResponseData});
            });
    };
}

// Load login session and make sure it isn't expired. This only happens on page load
export const loadLoginSession = (loginSessionId, isSSOLogout) => (dispatch, getStore) => {
    const tenantName = loadStorageItem(storageItems.TENANT_NAME);   //Retrieve tenantName from localstorage
    const isHostedSaas = loadStorageItem(storageItems.IS_HOSTED_SAAS);  //Load isHostedSaas for version display

    return api.readLoginSessionInfo(loginSessionId, tenantName)
        .then(result => {
            if (isSSOLogout === false) setCheckLoginSessionInterval(dispatch, getStore);
            dispatch({type: types.LOAD_LOGIN_SESSION_INFO, loginSessionId, result, isHostedSaas});
            if (isSSOLogout === false) fetchPolicies()(dispatch, getStore);
            // Load policies so deleteme button shows up as needed
        })
        .catch(catchLostServerConnection)
        .catch((failedResponseData) => {
            // If session no longer valid, clear from storage. Regardless, clear Redux store and don't display error
            if (!failedResponseData.errorHandled) {
                clearStorageItem(storageItems.LOGIN_SESSION_ID);
                clearStorageItem(storageItems.TENANT_NAME);
                clearStorageItem(storageItems.IS_HOSTED_SAAS);
            }

            dispatch({type: types.CLEAR_USER_INFO});
            if (isSSOLogout) {
                // Session is Invalidated , redirect user to SSO Login Page
                window.location.href = SSO_DEFAULT_LOGOUT_REDIRECT;
            }
        });
};

export const handleOSPAuthRedirect = (username, redirectUri = null) => (dispatch) => {
    dispatch({type: types.FETCH_TENANT_NAME});
    api.fetchTenantName(username)
        .then(response => {
            dispatch({type: types.REDIRECT_TO_OSP_LOGON});
            //Save to localStorage for retrieval on OSPs return
            saveStorageItem(storageItems.TENANT_NAME, response.tenantName);
            const appendUri = redirectUri == null ? '' : '&redirect_uri=' + redirectUri;
            window.location.href = OSP_LOGON_URL + response.tenantName + '&user_name=' + username + appendUri;
        })
        .catch(catchLostServerConnection)
        .catch(createToastFromJsonErrors);
};

export function selectLoginChainIndex(index) {
    return (dispatch) => dispatch({type: types.UPDATE_LOGIN_CHAIN, index});
}

function setCheckLoginSessionInterval(dispatch, getStore) {
    if (!checkLoginSessionIntervalID) {
        checkLoginSessionIntervalID = setInterval(() => checkLoginSession(dispatch, getStore),
            CHECK_LOGIN_SESSION_INTERVAL);
    }
}

const updateLastActivityTime = () => {
    lastActivityTime = new Date();
};

export function updateLoginFormData(key, value) {
    return (dispatch) => dispatch({type: types.UPDATE_LOGIN_FORM_DATA, key, value});
}

export function updateUsername(username) {
    return (dispatch) => dispatch({type: types.UPDATE_USERNAME, username});
}

// Update last activity time whenever user navigates
history.listen(updateLastActivityTime);
