/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import {apiFetch} from './json-fetch';
import { decamelizeKeys } from 'humps';
import {ensureEndpointSession} from './endpoint-session.api';

export function abortEnrollProcess(enrollProcessId, loginSessionId) {
    return apiFetch('DELETE', `enroll`, {
        headers: {
            'login-session-id': loginSessionId,
            'enroll-process-id': enrollProcessId
        }
    });
}

export function beginEnrollProcess(loginSessionId, methodId) {
    return apiFetch('POST', 'enroll', {
        data: {
            loginSessionId,
            methodId
        }
    });
}

export function createUserTemplate(userId, loginSessionId, enrollProcessId, categoryId = '', comment) {
    return apiFetch('POST', `users/${userId}/templates`, {
        data: {
            loginSessionId,
            enrollProcessId,
            categoryId,
            comment
        }
    });
}

export function deleteUserTemplate(userId, loginSessionId, templateId) {
    return apiFetch('DELETE', `users/${userId}/templates/${templateId}`, {
        headers: {
            'login-session-id': loginSessionId
        },
    });
}

export function doEnroll(enrollProcessId, loginSessionId, enrollData, keepCamelCase, templateIdToModify) {
    const camelizedData = { loginSessionId };
    if (templateIdToModify) {
        camelizedData.authTId = templateIdToModify;
    }

    const data = decamelizeKeys(camelizedData);
    if (enrollData) {
        data.response = enrollData;
    }

    return apiFetch('POST', `enroll/do_enroll`, {
        data,
        headers: {
            'enroll-process-id': enrollProcessId
        },
        preserveRequestCase: keepCamelCase
    });
}

export function getDefaultRecipient(userId, loginSessionId, methodId) {
    return apiFetch('POST', `users/${userId}/method/${methodId}/get_recipient`, {
        headers: {
            'login-session-id': loginSessionId
        }
    });
}

export const getTotpQrCode = ensureEndpointSession((endpointSessionId) => (isBase32Secret,serviceName,accountName) => {
    return apiFetch('POST', 'logon_method/TOTP:1', {
        data: {
            generateAuthenticator: true,
            isBase32Secret,
            endpointSessionId,
            serviceName,
            accountName
        }
    });
});

export const getWinHelloInfo = (userId, loginSessionId) => {
    return apiFetch('POST', `logon_method/WINHELLO:1/account_details/${userId}/get`, {
        headers: {
            'login-session-id': loginSessionId
        }
    });
};

export function modifyUserTemplate(userId, loginSessionId, enrollProcessId, templateId, comment) {
    const data = {
        loginSessionId,
        comment
    };

    if (enrollProcessId) {
        data.enrollProcessId = enrollProcessId;
    }

    return apiFetch('PUT', `users/${userId}/templates/${templateId}`, {
        data
    });
}
