
import './EnrolledMethods.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  exitAvailableMethodsView, viewChainAuthenticator, viewAuthenticator, getAuthenticatorLink
} from '../../actions/navigation.actions';
import {
  availableIndexedTemplatesType, categoriesType, indexedChainsType,
  requiredMethodsType
} from '../../types/types';
import t from '../../i18n/locale-keys';
import { getTemplateKey } from '../../utils/key-generator';
import MethodTile from '../../components/tiles/MethodTile';
import { fetchIndexedData, fetchRequiredMethods } from '../../actions/methods-display.actions';
import { unenrollableMethods } from '../../data/MethodData';
import withParams from '../WithParams';
import { clearStorageItem, loadStorageItem, saveStorageItem } from '../../utils/local-storage';
import history from '../../history';

class RequiredMethods extends React.PureComponent {

  constructor(props) {
    super(props);

    this.props.fetchIndexedData();
    this.props.fetchRequiredMethods();

    this.state = {
      redirecting: false
    }
  }

  getMethodsFromStorage() {
    let methodString = loadStorageItem("REQUIRED_METHODS");
    if (methodString) {
      return JSON.parse(methodString);
    }
    return null;
  }

  getMethodsFromURI() {
    let uri = new URL(window.location.href);
    let uriParse = uri.searchParams;
    if (uriParse) {
      let methods = uriParse.get("METHODS");
      if (methods) {
        let m = methods.replace("[", "");
        m = m.replace("]", "");
        let msplit = m.split(",");
        return msplit;
      }
    }
    return null;
  }

  allRequiredMethodsAreAvailable(required, available, enrolled) {
    let value = true;

    if (available == null || available == undefined || available.length < 1 ) {
      value = false;
      return value;
    }
    let a = Object.keys(available);
    let e = Object.keys(enrolled);
    let all = a + e;


    required.forEach(element => {

      if (available != null && !all.includes(element)) {
        value = false;
      }
    });
    return value;
  }

  getMethods() {
    const { requiredMethods } = this.props;

    let methods = this.getMethodsFromURI();
    if (methods) {
      saveStorageItem("REQUIRED_METHODS", JSON.stringify(methods));
      return methods;
    }

    methods = this.getMethodsFromStorage();
    if (methods) {
      return methods;
    }
    if (requiredMethods) {
      return requiredMethods.map(method => method.id);
    }

    return [];
  }

  handleSubmit() {
    clearStorageItem("REQUIRED_METHODS")
    window.location.assign("https://" + window.location.hostname + "/portal");
    // check completion status

    //remove method state from localstorage

    // submit to workflow

  }

  openTask(template) {
    const { enrolledIndexedTemplates } = this.props;
    let redirect = "?TASK_COMPLETE_REDIRECT=\\enrollment";
    let link = getAuthenticatorLink(template, enrolledIndexedTemplates) + redirect;
    history.navigate(link);
  }

  getMethodsContentFromTemplateList(templateList, workflowMethods) {
    if (!templateList || templateList.length < 1) {
      return null;
    }
    return templateList.filter((template) => {
      return !unenrollableMethods.includes(template.methodId) && workflowMethods.includes(template.methodId);
    }).map((template) => {
      const key = getTemplateKey(template);
      const handleClick = () => this.openTask(template);

      return <MethodTile id={key} key={key} onClick={handleClick} template={template} />;
    });
  }

  getMethodsContent(workflowMethods) {
    const { availableIndexedTemplates, enrolledIndexedTemplates } = this.props;

    if (!workflowMethods) {
      return (<div>{t.workflowEnrollmentRequiredError()}</div>);
    }
    if (!availableIndexedTemplates || !enrolledIndexedTemplates) {
      return (<div>{t.workflowEnrollmentLoading()}</div>);
    }

    // Get an array of available templates in alphabetical order so they can be displayed properly.
    const availableTemplates =
      Object.keys(availableIndexedTemplates)
        .map(methodId => availableIndexedTemplates[methodId])
        .sort((templateA, templateB) => templateA.methodTitle.localeCompare(templateB.methodTitle));

    const enrolledTemplates =
      Object.keys(enrolledIndexedTemplates)
        .map(methodId => enrolledIndexedTemplates[methodId][0])
        .sort((templateA, templateB) => templateA.methodTitle.localeCompare(templateB.methodTitle));

    let available = this.getMethodsContentFromTemplateList(availableTemplates, workflowMethods);
    let enrolled = this.getMethodsContentFromTemplateList(enrolledTemplates, workflowMethods);
    let hasNeededMethods = this.allRequiredMethodsAreAvailable(workflowMethods, availableIndexedTemplates, enrolledIndexedTemplates);


    return (
      <>
        {hasNeededMethods == false &&
          <>{t.workflowEnrollmentMissing()}</>
        }
        {available && available.length > 0 && <p className="description">
          {t.workflowEnrollmentInstructions()}
        </p>
        }

        <div>
          {available && available.length > 0 && <>
            <div className="methods-grid-label">{t.workflowEnrollmentRequired()}</div>
            <div className="ias-grid">
              {available}
            </div>
          </>}

          {enrolled && enrolled.length > 0 && <>
            <div className="methods-grid-label">{t.workflowEnrollmentCompleted()}</div>
            <div className="ias-grid">
              {enrolled}
            </div>
          </>}
        </div>
      </>);
  }

  cantClickCompleteButton() {
    const { enrolledIndexedTemplates } = this.props;
    let rMethods = loadStorageItem("REQUIRED_METHODS");
    const { requiredMethods } = this.props;
    if (rMethods) {
      const methods = JSON.parse(rMethods);
      if (enrolledIndexedTemplates) {
        let items = Object.keys(enrolledIndexedTemplates);
        let numCompleted = 0;
        methods.map((method) => {
          if (items.includes(method)) {
            numCompleted = numCompleted + 1;
          }
        })
        if (numCompleted === methods.length) {
          return false;
        } else {
          return true;
        }
      }
    }
    else if (requiredMethods) {
      if (enrolledIndexedTemplates) {
        let items = Object.keys(enrolledIndexedTemplates);
        let numCompleted = 0;
        requiredMethods.map((method) => {
          if (items.includes(method.id)) {
            numCompleted = numCompleted + 1;
          }
        })
        if (numCompleted === requiredMethods.length) {
          return false;
        } else {
          return true;
        }
      }
    }

    return true;
  }

  render() {
    let workflowMethods = this.getMethods();
    let submit = this.cantClickCompleteButton();
    let content = this.getMethodsContent(workflowMethods);

    return (
      <div className="ias-content-padding">
        <h2>{t.workflowEnrollmentTitle()}</h2>
        {content}
        <div className="authenticator-buttons">
          <button className="ias-button" id="Submit_Buton" onClick={this.handleSubmit} type="button" disabled={submit}>
            {t.buttonSubmit()}
          </button>
        </div>
      </div>
    );
  }
}

RequiredMethods.propTypes = {
  availableIndexedTemplates: availableIndexedTemplatesType,
  categories: categoriesType,
  indexedChains: indexedChainsType,
  nonDefaultCategoriesEnrolled: PropTypes.bool,
  requiredMethods: requiredMethodsType
};
const mapStateToProps = ({ methodsDisplay: { categories, indexedData, requiredMethods } }) => ({ ...indexedData, categories, requiredMethods });

const mapDispatchToProps = {
  fetchIndexedData,
  viewChainAuthenticator,
  viewAuthenticator,
  exitAvailableMethodsView,
  fetchRequiredMethods
};



export default withParams(connect(mapStateToProps, mapDispatchToProps)(RequiredMethods));